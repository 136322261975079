//=================================================================================================
// Reset box sizing to border box
//=================================================================================================
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}


//=================================================================================================
// Remove figure margin
//=================================================================================================
figure {
    margin: 0;
}

body:not(.home) {
    .header-top__logo, .footer__infos__logo, .footer__infos__coordonnees, .footer__infos__horaires, .footer__button {
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
        visibility: visible !important;
        opacity: 1;
    }
}

