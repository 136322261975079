.header {
    position: relative;

    &--fixed {
        .header-top {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 0;
            z-index: 20;
            background-color: $color-light;
            box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
            transition: all 0.1s ease-in-out;

            &__logo {
                height: 100px;

                &-menu {
                    display: none;
                }

                &-fixe {
                    display: flex;
                }
            }
        }
    }
}

.header-top {
    &__container {
        display: flex;
        justify-content: space-between;
        height: 100px;
    }

    &__logo {
        width: 155px;
        height: 250px;
        background-color: $color-main;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 5;
        transition: all $duration ease-in-out;
    }

    &__title {
        margin: 0;
        padding: 0;
    }

    &__titre {
        font-size: 0;
        line-height: 0;
        margin: 0;
    }    

    &__logo-menu {
        display: flex;
    }

    &__logo-fixe {
        display: none;
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 50px;
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    height: 30px;
    
    &__container {
        display: flex;
        gap: 18px;
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }

    .tool {
        margin: 0;
        padding: 0;
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: unset;
        border: none;
        transition: all $duration ease-in-out;

        svg {
            width: 30px;
            height: 30px;
            fill: $color-dark;
            transform: scale(1);
            transition: all 0.2s ease-in-out;
        }

        &:hover, &:focus {
            svg {
                width: 100%;
                height: 100%;
                fill: $color-main;
                opacity: 0.7;
                transform: scale(1.1);
            }
        }
    }

    &__translate {
        position: relative;

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 65px;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 5px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-main;
            border-radius: $border-radius;
            transform: translateX(-50%);
            z-index: 1;
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                position: absolute;
                top: -6px;
                left: 50%;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-main;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family--heading;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.25rem;
                color: $color-white;
        
                &:hover, &:focus {
                    color: $color-white;
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    color: $color-white;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            position: absolute;
            bottom: -10px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content:center;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-white;
            border-radius: $border-radius--round;
            border: none;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        a.glink.gt-current-lang {
            font-weight: normal;
        }
    }

    &-view {
        position: absolute;
        top: 165px;
        width: 100%;
        z-index: 1;
    
        .container--tools-view {
            display: flex;
            justify-content: flex-end;
    
            #google_translate_element {
                display: none;
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 100px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover, &:focus {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        top: 100px !important;
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
    }

}


// 960
@media screen and (max-width: $medium) {
    .header-top {
        &__logo {
            width: 120px;
            height: 170px;

            svg {
                width: 62px;
                height: 110px;
            }
        }

        &__content {
            #nav-main {
                display: none;
            }
            .tools__search {
                display: none;
            }
        }
    }

    .tools {
        &__container {
            gap: 15px;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(50% - -15px) !important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {
    .header-top {
        &__logo {
            width: 120px;
            height: 170px;
        }
    }
    .tools {
        &__container {
            gap: 10px;
            padding: 0;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: 180px !important;
    }

}
