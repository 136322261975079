.footer {
    background-color: $color-second;
    padding: 75px 0 40px;

    &__infos {
        display: flex;
        justify-content: space-between;

        &__logo {
            width: 90px;
            height: 160px;

            svg {
                width: 100%;
                height: 100%;
                fill: $color-main;
            }
        }

        &__title {
            font-family: $font-family;
            font-weight: $font-weight-medium;
            font-size: 20px;
            line-height: 25px;
        }

        &__text {
            font-family: $font-family--heading;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 22px;
            margin-top: 15px;
            margin-bottom: 10px;
        }

        &__phone {
            font-family: $font-family--heading;
            font-weight: $font-weight-medium;
            font-size: 16px;
            line-height: 22px;
            transition: all $duration ease-in-out;

            &:hover {
                opacity: .7;
            }
        }
    }

    &__button {
        background: $color-main;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 30px;
        width: 155px;
        height: 245px;
        padding: 0 25px 30px;
        margin-top: -95px;
        transition: all $duration ease-in-out;

        p {
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            text-align: center;
            color: $color-white;
            transition: all $duration ease-in-out;
        }

        svg {
            transition: all $duration ease-in-out;
        }

        &:hover {
            opacity: 0.8;

            p {
                color: $color-white;
                transform: translateY(-5px);
            }

            svg {
                transform: translateY(5px);
            }
        }
    }

    &__menu {
        margin-top: 75px;

        &__list {
            display: flex;
            gap: 80px;
        }

        &__item {
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 19px;
            border-top: 1px solid rgba(34, 34, 34, 0.5);
            padding-top: 10px;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 0;
                height: 1px;
                left: 0;
                top: 0;
                background-color: $color-main;
                transition: all $duration ease-in-out;
            }

            &:hover {
                &:before {
                    width: 100%;
                }
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        &__infos {
            &__logo {
                margin-right: 70px;
            }

            &__coordonnees {
                margin-right: 35px;
                width: 230px;
            }

            &__horaires {
                margin-right: 80px;
                width: 280px;
            }

            &__text {
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__menu {
            &__list {
                gap: 45px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .footer {
        padding: 40px 0 135px;

        &__infos {
            flex-wrap: wrap;
            justify-content: unset;

            &__logo {
                order: 3;
                margin-right: 115px;
                margin-top: 40px;
            }

            &__coordonnees {
                order: 2;
                margin-left: 50px;
            }

            &__horaires {
                order: 4;
                margin-top: 50px;
            }
        }

        &__button {
            order: 1;
            height: 220px;
            margin-top: -60px;
        }

        &__menu {
            margin-top: 60px;

            &__list {
                max-width: 380px;
                flex-wrap: wrap;
                gap: 30px 40px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .footer {
        &__infos {
            flex-direction: column;

            &__logo {
                order: 2;
                margin-right: unset;
                margin-top: 50px;
            }

            &__coordonnees {
                order: 3;
                margin-left: 0;
                margin-top: 40px;
            }

            &__horaires {
                order: 4;
                margin-top: 40px;
            }
        }

        &__button {
            order: 1;
        }

        &__menu {
            margin-top: 50px;
        }
    }
}