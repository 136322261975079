@keyframes menuClose {
    from {
        display: none;
        opacity: 0;
    }
    20% {
        display: flex;
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.nav-main {
    &__socials {
        position: absolute;
        left: calc(50% + 290px);
        top: 37px;
        display: flex;
        gap: 5px;

        a {
            width: 35px;
            height: 35px;

            svg {
                fill: $color-white;
                width: 100%;
                height: 100%;
                transition: all 0.2s ease-in-out;
            }

            &:hover,
            &:focus {
                svg {
                    transform: scale(1.2);
                }
            }
        }
    }

    &__container {
        position: fixed;
        right: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
        transform: translateY(-100vh);
        transition: 0.5s ease-in-out;
        z-index: 11;
        height: 100vh;
        width: 100vw;
        background: $color-main;
        overflow: hidden;

        .container {
            position: relative;
            width: 100%;

            &:before {
                position: absolute;
                content: "";
                height: 100vh;
                width: 710px;
                left: -360px;
                top: 0;
                background: $color-third;
            }
        }
    }

    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100vw;
        z-index: 15;
        padding-top: 100px;
        width: fit-content;
        height: 100%;
        transition: 0.35s ease-in-out;

        .nav-main-item {
            &:hover,
            &:focus {
                .sub-menu {
                    display: flex;
                }

                .nav-main-link {
                    opacity: 0.3;

                    &:before {
                        opacity: 1;
                    }
                }
            }

            a {
                transition: all 1.7s ease-in-out;
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: 0.05em;
                width: 305px;
                margin-bottom: 40px;
                opacity: 1;
                background-color: transparent;
                color: $color-white;
                text-transform: uppercase;
                transition: color $duration ease-in-out;

                span {
                    text-transform: lowercase;
                }

                &:before {
                    position: absolute;
                    content: "";
                    width: 70px;
                    height: 1px;
                    top: 50%;
                    right: -63px;
                    transform: translateY(-50%);
                    background-color: $color-white;
                    opacity: 0;
                    transition: color $duration ease-in-out;
                }
            }

            .sub-menu {
                position: absolute;
                top: 100px;
                left: 350px;
                width: 280px;
                height: 100vh;
                padding-left: 185px;
                margin-left: -65px;
                transition: all $duration ease-in-out;
                display: none;

                .nav-drop {
                    height: 100vh;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    position: relative;

                    &__title {
                        font-family: $font-family;
                        font-weight: $font-weight-normal;
                        font-size: 20px;
                        line-height: 20px;
                        position: absolute;
                        color: $color-white;
                        transform: rotate(-90deg);
                        top: 120px;
                        left: -175px;
                        opacity: 0.3;
                        text-transform: uppercase;
                        width: 265px;
                        height: 20px;
                        text-align: end;
                    }
                }

                .nav-child-item {
                    position: relative;
                    font-family: $font-family;
                    font-weight: $font-weight-light;
                    font-size: $font-size--5;
                    line-height: 20px;
                    width: 275px;
                    min-width: 275px;
                    max-width: 275px;
                    color: $color-white;
                    transition: all $duration;

                    a {
                        position: relative;
                        display: flex;
                        z-index: 3;
                        animation: unset !important;
                        transition: all $duration;
                    }

                    &:hover,
                    &:focus {
                        padding-left: 15px;

                        a {
                            color: $color-white;
                        }
                    }
                }
            }

            &.active,
            &.menu-item-has-children.active {
                .sub-menu {
                    display: block;
                }
            }
        }
    }

    &__button {
        // display: none;
        opacity: 0;
        position: absolute;
        z-index: -1;

        ~ .menu {
            clear: both;
            transform: scaleY(0);
            transform-origin: 50% 0;
            transition: transform 0.3s ease;
        }

        &:checked {
            ~ .nav-main__container {
                transform: translateY(0);

                .container .menu {
                    transform: scaleY(1);
                    display: flex;

                    li a {
                        animation: slide-left 1.2s ease-in-out 0s 1 normal forwards;

                        @keyframes slide-left {
                            from {
                                opacity: 0;
                                transform: translateX(-650px);
                            }

                            to {
                                opacity: 1;
                                transform: translateX(0);
                            }
                        }

                        &:focus {
                            color: $color-white;
                        }
                    }
                }
            }

            ~ .nav-main__icon {
                background-color: transparent;
                &:before {
                    display: none;
                }

                &:not(.steps) {
                    .nav-main__icon__svg-container {
                        &:before,
                        &:after {
                            top: 0;
                        }
                    }
                }

                .nav-main__icon__close {
                    display: flex;
                    align-items: center;
                    gap: 17px;
                    animation: 0.8s menuClose;
                    top: 40px;
                    left: calc(50% + 475px);
                    position: relative;
                    color: $color-white;
                    position: fixed;
                    z-index: 15;
                    font-family: $font-family;
                    font-weight: $font-weight-normal;
                    font-size: $font-size--text-medium;
                    line-height: 20px;
                    text-transform: uppercase;

                    svg {
                        display: flex;
                    }
                }
            }
        }
    }

    &__icon {
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
        margin-left: 0;
        background-color: transparent;
        transition: all $duration ease-out;

        &--container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            width: fit-content;
            height: 30px;
            gap: 15px;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: $font-size--text-medium;
            line-height: 20px;
            text-transform: uppercase;
            transition: all $duration ease-out;

            &.focused {
                color: $color-main;
                span {
                    background-color: $color-main;

                    &:before,
                    &:after {
                        background-color: $color-main;
                    }
                }
            }

            &:hover,
            &:focus {
                color: $color-main;
                span {
                    background-color: $color-main;

                    &:before,
                    &:after {
                        background-color: $color-main;
                    }
                }
            }
        }

        &__burger {
            width: 18px;
            height: 12px;
            position: absolute;
        }

        &__close {
            display: none;
        }

        svg {
            display: none;
        }

        &__svg-container {
            position: relative;
            display: block;
            height: 2px;
            width: 16px;
            left: unset;
            background-color: $color-dark;
            transition: all $duration ease-out;

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                height: 100%;
                width: 16px;
                background-color: $color-dark;
                transition: all $duration ease-out;
            }

            &:before {
                top: 6px;
            }

            &:after {
                top: -6px;
                height: 2px;
            }
        }
    }
}

//======================================================================================================
// Menu en 1 clic
//======================================================================================================

.clic {
    position: absolute;
    right: 10px;
    top: 295px;

    &__title {
        font-family: $font-family;
        font-weight: $font-weight-light;
        font-size: 30px;
        line-height: 30px;
        color: $color-white;
        text-transform: uppercase;
        transform: rotate(-90deg);
        position: absolute;
        width: fit-content;
        margin: 0;
        padding: 0;
        top: 58px;
        left: -115px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        list-style: none;
    }

    &__link {
        color: $color-white;
        width: 260px;
        border-top: 1px solid $color-white;
        font-family: $font-family;
        font-weight: $font-weight-light;
        font-size: 16px;
        line-height: 25px;
        color: $color-white;
        text-transform: uppercase;
        padding-top: 10px;
        color: $color-white;
        transition: all $duration ease-in-out;

        p {
            color: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover,
        &:focus {
            color: $color-white;
            opacity: 0.5;

            > a {
                color: $color-white;
            }
        }

        > a {
            &:focus {
                color: $color-white !important;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .nav-main {
        &__button:checked ~ .nav-main__icon .nav-main__icon__close {
            left: calc(50% + 360px);
        }

        &__socials {
            left: calc(50% + 165px);
        }

        &__container {
            .container {
                &:before {
                    width: 670px;
                }
            }
        }

        .menu .nav-main-item {
            .nav-main-link {
                font-size: 18px;

                &:before {
                    right: -20px;
                    width: 50px;
                }
            }

            .sub-menu {
                left: 305px;
                padding-left: 170px;

                .nav-drop {
                    &__title {
                        left: -195px;
                    }
                }

                .nav-child-item {
                    margin-left: -25px;
                    font-size: 16px;
                    width: 230px;
                    min-width: 230px;
                    max-width: 230px;
                }
            }
        }
    }

    .clic {
        &__link {
            width: 180px;
        }

        &__title {
            font-size: 25px;
            top: 49px;
            left: -88px;
        }
    }
}
