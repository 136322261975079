.menu-fixe {
    display: none;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .menu-fixe {
        display: flex;
        width: 100vw;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        z-index: 20;
        height: 70px;
        box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);

        &__container {
            margin: 0 auto;
            background: $color-main;
            width: 100%;
            display: flex;
            align-items: center;

            .container {
                display: flex;
                min-width: 620px;
                justify-content: space-between;
                align-items: flex-start;
            }
        }

        &__left {
            display: flex;
            align-items: center;
            gap: 50px;

            .nav-main__icon--container {
                color: $color-white;

                .nav-main__icon__svg-container {
                    background-color: $color-white;

                    &:before, &:after {
                        background-color: $color-white;
                    }
                }
            }

            .tools__search {
                margin: 0;
                padding: 0;
            }
        }

        &__button {
            border: none;

            &:hover {
                svg {
                    fill: $color-white;
                }
            }
        }
    }
    
    // CLIC MENU
    .clic-menu {

        &__socials {
            position: absolute;
            left: calc(50% + -295px);
            top: 22px;
            display: flex;
            gap: 5px;
    
            a {
                width: 35px;
                height: 35px;
    
                svg {
                    fill: $color-white;
                    width: 100%;
                    height: 100%;
                    transition: all 0.2s ease-in-out;
                }
    
                &:hover, &:focus {
                    svg {
                        transform: scale(1.2);
                    }
                }
            }
        }


        &__container {
            z-index: 1;
            min-width: 100%;
            min-height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            height: 0;
            visibility: hidden;
            opacity: 0;
            text-align: center;
            padding-top: 20px;
            z-index: -1;
            transition: all 0.3s ease-in-out;

            &.overlay {
                visibility: visible;
                opacity: 1;
                padding-top: 100px;
                background: $color-third;
            }
            
        }

        .clic {
            position: relative;
            right: unset;
            top: unset;

            &__container {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 580px;
            }

            &__title {
                transform: unset;
                left: unset;
                position: relative;
                top: unset;
                width: 260px;
                text-align: start;
            }

            &__list {
                flex-direction: unset;
                gap: 20px 60px;
                flex-wrap: wrap;
            }

            &__link {
                text-align: start;
                width: 260px;

                &:hover, &:active, &:focus {
                    color: $color-white;
                }
            }
        }
    
        &__icon {
            cursor: pointer;
            width: 100%;
            height: 100%;
            margin: 0;
            margin-left: 0;
            background-color: transparent;
            transition: all $duration ease-out;
    
            &--container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                color: $color-white;
                width: fit-content;
                height: 30px;
                gap: 15px;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: $font-size--text-medium;
                line-height: 20px;
                text-transform: uppercase;
                transition: all $duration ease-out;
                cursor: pointer;
                overflow: visible;
            }

            &__close {
                visibility: hidden;
                opacity: 0;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: 20px;
                line-height: 20px;
                color: $color-white;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
                position: absolute;
                top: 25px;
                left: calc(50% + 205px);

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    // NAV MENU
    .nav-menu {
        &__container {
            z-index: 1;
            min-width: 100%;
            min-height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            height: 0;
            visibility: hidden;
            opacity: 0;
            text-align: center;
            padding-top: 20px;
            z-index: -1;
            transition: all 0.3s ease-in-out;

            &.overlay {
                visibility: visible;
                opacity: 1;
                padding-top: 70px;
                background: $color-third;
            }
            
        }
    
        &__icon {
            cursor: pointer;
            width: 100%;
            height: 100%;
            margin: 0;
            margin-left: 0;
            background-color: transparent;
            transition: all $duration ease-out;
    
            &--container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                color: $color-white;
                width: fit-content;
                height: 30px;
                gap: 15px;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: $font-size--text-medium;
                line-height: 20px;
                text-transform: uppercase;
                transition: all $duration ease-out;
                cursor: pointer;
                overflow: visible;

                svg {
                    path {
                        fill: $color-white;
                    }
                }
            }

            &__close {
                visibility: hidden;
                opacity: 0;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: 20px;
                line-height: 20px;
                color: $color-white;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
                position: absolute;
                top: 25px;
                left: calc(50% + 205px);
                z-index: 15;

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &__back {
                visibility: hidden;
                opacity: 0;
                cursor: pointer;
                position: absolute;
                top: 25px;
                left: calc(50% + -300px);
                height: 30px;
                display: flex;
                align-items: center;
                z-index: 15;

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &__list {
            max-width: 620px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 75vh;
        }

        &__item {
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 20px;
            line-height: 20px;
            color: $color-white;
            text-transform: uppercase;
            cursor: pointer;
            display: flex;
            height: 60px;

            & .nav-menu__sub {
                left: 100vw;
            }

            &.active {
                .nav-menu__sub {
                    left: 0;
                }
            }
        }

        &__sub {
            position: absolute;
            display: flex;
            width: 620px;
            background: $color-main;
            height: 100vh;
            padding-top: 40px;
            top: 0;
            width: 100vw;
            z-index: 5;
            left: 0;
            transition: all $duration ease-in-out;

            .nav-drop {
                margin: 50px 0 0;
                display: flex;
                flex-direction: column;
                gap: 30px;
                height: 67vh;
                width: 620px;
                align-items: flex-start;
                position: relative;
                left: calc(50% + -300px);
                overflow: auto;

                p {
                    font-family: $font-family;
                    font-weight: $font-weight-normal;
                    font-size: 20px;
                    line-height: 20px;
                    color: $color-white;
                    opacity: .3;
                }

                li {
                    font-family: $font-family;
                    font-weight: $font-weight-light;
                    font-size: 18px;
                    line-height: 20px;
                    color: $color-white;
                    text-transform: none;
                    text-align: start;
                }
            }
        }

        &__link {
            width: 280px;
            margin: 0 auto;
            text-align: start;

            span {
                text-transform: lowercase;
            }
            
            &:hover, &:active, &:focus {
                color: $color-white;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .menu-fixe {
        &__container {
            .container {
                min-width: 300px;
                max-width: 300px;
            }
        }

        &__left {
            gap: 40px;
        }
    }

    .clic-menu {

        &__socials {
            left: calc(50% - 160px);
        }
        
        .clic {
            &__container {
                width: 300px;
            }

            &__list {
                flex-wrap: nowrap;
                flex-direction: column;
            }
        }

        &__icon {

            &__close {
                left: calc(50% + 30px);
            }
        }
    }

    .nav-menu {
        &__icon {
            &__back {
                left: calc(50% - 140px);
            }

            &__close {
                left: calc(50% + 30px);
            }

            &--container {
                gap: 10px;
                p {
                    display: none;
                }
            }
        }

        &__container {
            &.overlay {
                padding-top: 100px;
            }
        }

        &__list {
            height: 70vh;
        }

        &__sub {
            .nav-drop {
                left: calc(50% - 140px);
                width: 300px;
            }
        }
    }
}