.breadcrumb {
    margin: 30px 0 0;
    
    ul  {
        display: flex;
        flex-wrap: wrap;
        gap: 0px 40px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-dark;
        position: relative;
        font-family: $font-family--heading;
        font-weight: $font-weight-light;
        font-size: 18px;
        line-height: 25px;

        &:not(:last-child) {
            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/st-quay-portrieux/assets/src/images/sprite-images/Pictos/ic-arrow-ariane.svg);
                background-color: $color-dark;
                mask-repeat: no-repeat;
                top: unset;
                right: -25px;
                width: 8px;
                height: 10px;
                -webkit-mask-size: 8px;
                mask-size: 8px;
            }
        }

        &:first-child {
            padding-left: 0;
        }

        a {
            transition: all $duration ease-in-out;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
        }
    }

}
