//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    display: flex;
    justify-content: center;
    padding: 50px 0;
    gap: 80px;

    li {
        position: relative;

        a, button, div {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: fit-content;
            width: fit-content;
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: none;
            font-family: $font-family;
            font-weight:$font-weight-bold;
            letter-spacing: 0.15em;
            font-size: 15px;
            line-height: 20px;
            text-transform: uppercase;
            color: $color-main;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 0;
                height: 1px;
                left: 5px;
                bottom: 0;
                background-color: $color-main;
                transition: all $duration ease-in-out;
            }

            svg {
                width: 26px;
                height: 26px;
                margin-right: 14px;
                fill: $color-main;
            }

            &:hover, &:focus, &:active {
                background-color: transparent;
                color: $color-main;

                &:before {
                    width: 98%;
                }

                svg {
                    fill: $color-main;
                }
            }
        }

        &:last-child a,
        &:last-child button,
        &:last-child div {
            border-right: 0;
        }

        .action-share {
            transition: 0s;
        }

    }

    .share-list {
        display: none;
    }

    &.shared-on {

        .action-share {
            opacity: 0;
        }

        .share-list {
            display: flex;
            position: absolute;
            top: -5px;
            left: 0;

            li {
                margin-right: 10px;
            }

            a, button, div {

                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                padding: 0;
                background-color: $color-white;
                border: 0;
                border-radius: $border-radius--round;
                box-shadow: $shadow;
                opacity: 1;

                &:hover, &:focus {
                    background-color: $color-white;
                    border: 0;

                    svg {
                        fill: $color-main;
                        opacity: .7;
                    }

                }

                svg {
                    margin: 0;
                    transform: scale(1.3);
                    color: $color-gray;
                }

                &:before {
                    display: none;
                }

                &.close {
                    svg {
                        // transform: scale(0.8);
                        // stroke: $color-main;
                        fill: $color-main;
                        transform: scale(1.5);
                    }
                    &:hover, &:focus {
                        svg {
                            fill: $color-main;
                            // stroke: $color-main;
                        }
                    }
                }

                #post_url {
                    position: absolute;
                    opacity: 0;
                }
            }

            .copy-confirm {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 35px;
                left: 50%;
                z-index: 2;
                width: 105px;
                height: 30px;
                opacity: 0;
                background: $color-gray;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: none;
                color: $color-white;
                transform: translate(-50%, 0);

                &.show-hide {
                    animation : show-hide 2s;
                }
            }

        }
    }

}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .actions-post {
        margin: 20px 0 70px;
        gap: 60px;
    }
}

// 960
@media screen and (max-width: $medium) {

    .actions-post {
        margin: 20px 0 70px;
        gap: 40px;
        
        &.shared-on {
            margin: 60px auto 20px;

            .share-list {
                display: flex;
                position: absolute;
                top: -50px;
                left: -135px;
                filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
            }

            .action-share {
                opacity: 1;
            }

        }
    }

}


// 640
@media screen and (max-width: $small) {

    .actions-post {
        margin: 20px auto 50px;
        padding: 10px 0;

        > li {
            > a, button, div {
                justify-content: center;
                width: 40px;
                height: 40px;
                margin: 0 20px;
                padding: 0;
                background: $color-main;
                border-radius: 50px;

                span {
                    display: none;
                }
                svg {
                    fill: $color-white;
                    margin: 0;
                }

                &:hover, &:focus, &:active {
                    background: rgba(26, 31, 76, .7);
                    svg {
                        fill: $color-white;
                    }
                }

                &:before {
                    display: none;
                }
            }
        }

        &.shared-on {
            .share-list {
                left: -225px;

                button {
                    margin: 0;

                    svg {
                        fill: $color-main;
                    }
                }
            }
        }

    }

}
