.search-container {
    display: flex;
    position: relative;
    align-items: center;

    .searchInput {
        padding: 24px 40px 24px 24px;
        border: none;
        background-color: white;
        border-radius: 50px;
        font-family: $font-family--heading;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 22px;
        color: $color-dark;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        opacity: 1;

        &::placeholder {
            font-family: $font-family--heading;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 22px;
            color: $color-dark;
            opacity: 1;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .search-svg {
        width: 70px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            width: 15px;
            height: 15px;
            fill: $color-main;
            transition: all $duration;
        }

        &:hover {
            svg {
                fill: $color-main;
                opacity: 0.7;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: -2px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-top: 1px solid $color-gray;

    li {
        position: relative;
        background: $color-white;
        border-bottom: 1px solid $color-bg--neutral;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-gray;
            transform: translate(0, -50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-gray;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 20px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $color-second;
    transition: all 0.65s;

    .container {
        position: relative;
        height: 100%;

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 60px;
            line-height: 55px;
            letter-spacing: 0.1em;
            color: $color-dark;
            text-transform: uppercase;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;
            z-index: 5;

            .searchInput {
                border: none;
                background-color: white;
                border-radius: 50px;
                font-family: $font-family--heading;
                font-weight: $font-weight-normal;
                font-size: 16px;
                line-height: 22px;
                color: $color-dark;

                &::placeholder {
                    font-family: $font-family--heading;
                    font-weight: $font-weight-normal;
                    font-size: 16px;
                    line-height: 22px;
                    color: $color-dark;
                }
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
            padding-top: 60px;
            top: -60px;
            border-radius: 30px;
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
            background-color: $color-white;

            li {
                font-family: $font-family--heading;
                font-weight: $font-weight-normal;
                font-size: 16px;
                line-height: 20px;
            }

            li:first-child {
                border-top: 1px solid $color-light;
            }

            li:last-child {
                border-radius: 0 0 30px 30px;

                a {
                    border-radius: 0 0 30px 30px;
                }
            }
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 0;
        width: 50px;
        height: 50px;
        padding: 0;
        background: $color-main;
        border-radius: 50%;
        transition: all $duration;

        svg {
            width: 25px;
            height: 25px;
            fill: $color-white;
            transition: all $duration;
        }

        &:hover {
            background: transparent;
            svg {
                fill: $color-main;
            }
        }
    }
}

//======================================================================================================
// Search Page
//======================================================================================================
.filter {
    .search-container {
        .search-svg {
            width: 70px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                width: 15px;
                height: 15px;
                fill: $color-main;
                transition: all $duration;
            }

            &:hover {
                svg {
                    fill: $color-main;
                    opacity: 0.7;
                }
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }
}

// 960
@media screen and (max-width: $medium) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }
}

// 640
@media screen and (max-width: $small) {
    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        font-size: 2.5em;
    }

    .search-popup .search-close {
        width: 45px;
        height: 45px;
        svg {
            width: 25px;
            height: 25px;
        }
    }

    .search-container .search-svg {
        width: 40px;
        margin-right: 12px;
        background: $color-white;
        border-radius: 50%;
    }
}
