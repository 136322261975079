.bloc-content--404 {
    h2 {
        margin: 95px 0 50px;
        border: 0;
        font-size: 8.5rem;
        text-align: center;
        color: $color-main;
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;

    .search-container {
        position: relative;
        z-index: 2;
    }

    .searchResult {
        padding-top: 60px;
        top: -60px;
        border-radius: 30px;
        filter: drop-shadow(0 0 5px rgba(0,0,0,.1));
        background-color: $color-white;
        position: relative;
        z-index: 0; 
    
        li {
            font-family: $font-family--heading;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 20px;
        }
    
        li:first-child {
            border-top: 1px solid $color-light;
        }
    
        li:last-child {
            border-radius: 0 0 30px 30px;
    
            a {
                border-radius: 0 0 30px 30px;
            }
        }
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            font-size: 7rem;
        }
    }

}
