.cover {
    position: relative;

    &__image-wrapper {
        width: 1260px;
        height: 450px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background-color: $color-second;
        padding: 100px 0 50px;
        margin-top: -100px;
    }

    &__title {
        margin: 30px 0 15px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-light;
            font-size: $font-size--5;
        }
    }

    &__intro {
        color: $color-text;
        font-family: $font-family--heading;
        font-weight: $font-weight-normal!important;
        font-size: 20px!important;
        line-height: 25px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            width: 1030px;
            height: 410px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            width: 710px;
            height: 290px;
        }

        &__title {
            font-size: 45px;
            line-height: 50px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            width: 640px;
            height: 270px;
        }
    }

}
