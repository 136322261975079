//=================================================================================================
// Buttons in general
//=================================================================================================
#{$btn} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin: 5px 14px 5px 0;
    padding: $btn-padding;
    background: $btn-bg;
    border: none;
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-weight: $font-weight-bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.15em;
    color: $btn-color;
    text-transform: $btn-text-transform;
    text-decoration: none;
    text-align: center;
    transition: all $duration ease;

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        color: $color-white;
        background: rgba(26, 31, 76, .7);

        svg {
            fill: $color-white;
        }
    }

    svg {
        width: 26px;
        height: 26px;
        fill: $color-white;
        transition: all $duration ease;
    }
}


//=================================================================================================
// Buttons specifics
//=================================================================================================
.button {

    //===============================================
    // Button variant 1 (Background and color change)
    //===============================================
    &--variant {
        color: $btn-variant-color;
        background: $btn-variant-bg;
        border-color: $btn-variant-border-color;

        svg {
            fill: $btn-variant-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-variant-color--hover;
            background: $btn-variant-bg--hover;
            border-color: $btn-variant-border-color--hover;

            svg {
                fill: $btn-variant-color--hover;
            }
        }
    }


    //===============================================
    // Button filter
    //===============================================
    &--filter {
        color: $color-dark;
        background: transparent;
        border-color: $color-dark;
        margin-right: 5px;
        font-family: $font-family;
        font-weight: 275;
        font-size: 18px;
        line-height: 20px;
        border: 1px solid $color-dark;
        padding: 8px 15px;
        letter-spacing: 0;

        svg {
            fill: $btn-filter-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $color-white;
            background: $color-main;
            border-color: $color-main;
        }
    }


    //===============================================
    // Button toggler
    //===============================================
    &--toggler,
    &--toggler:active,
    &--toggler:focus {
        color: $color-dark;
        background: transparent;
        border-color: $color-dark;
        margin-right: 5px;
        font-family: $font-family;
        font-weight: 275;
        font-size: 18px;
        line-height: 20px;
        border: 1px solid $color-dark;
        padding: 5px 15px;
        letter-spacing: 0;

        svg {
            fill: $color-dark;
        }

        &.is-open {
            svg {
            transform: rotate(180deg);
            }
        }

        &:hover {
            color: $color-white;
            background: $color-main;
            border-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }


    //===============================================
    // Button empty
    //===============================================
    &--empty {
        color: $color-dark;
        background: transparent;
        border-color: $color-dark;
        margin-right: 5px;
        font-family: $font-family;
        font-weight: 275;
        font-size: 18px;
        line-height: 20px;
        border: 1px solid $color-dark;
        padding: 8px 15px;
        letter-spacing: 0;

        svg {
            fill: $color-dark;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $color-white;
            background: $color-main;
            border-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }
}


//===============================================
// Specifics links
//===============================================
.link-document {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    cursor: pointer;
    font-family: $font-family;
    font-weight: $font-weight-normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: underline;
    text-transform: uppercase;
    color: $color--card;
    transition: all $duration ease;

    svg {
        width: 30px;
        height: 30px;
        fill: $color-text;
        margin-right: 5px;
    }

    &:hover {
        text-decoration: underline;
    }
}


//===============================================
// Animation Voir plus
//===============================================
.showX {
    opacity: 1;
    transition: all 0.30s ease-out;
}

.hideX {
    opacity: 0;
    transition: all 0.30s ease-in;
    pointer-events: none;
}

.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}


//===============================================
// Reset button
//===============================================
.reset-button-style {
    display: inherit;
    margin: 0;
    padding: 0;
    height: auto;
    background: inherit;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
    text-align: inherit;
    transition: none;

    svg {
        width: inherit;
        height: inherit;
        fill: inherit;
        margin: 0;
        transition: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background: inherit;
        border-color: inherit;
        color: inherit;

        svg {
            fill: inherit;
        }
    }
}