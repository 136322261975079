.front-page {
    position: relative;
    overflow: hidden;
    z-index: 0;

    h3 {
        margin: 0;
        padding: 0;
        font-family: $font-family;
        font-weight: $font-weight-medium;
        font-size: 20px;
        line-height: 25px;
        color: $color-dark;
        transition: all $duration ease-in-out;
    }

    &__title {
        margin: 0;
        padding: 0;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 60px;
        line-height: 55px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: $color-dark;
    }

    &__description {
        font-family: $font-family--heading;
        font-weight: $font-weight-normal;
        font-size: 20px;
        line-height: 22px;
        color: $color-dark;
    }

    &__introduction {
        font-family: $font-family--heading;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 22px;
        color: $color-dark;
    }

    &__button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: fit-content;
        margin-top: 20px;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        padding: 0 10px;
        z-index: 2;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 15px;
            bottom: -5px;
            left: 0;
            background-color: $color-light;
            z-index: -1;
        }

        &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 15px;
            top: 10px;
            left: 0;
            background-color: $color-main;
            z-index: -1;
            opacity: 0.3;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            &:after {
                width: 100%;
            }
        }
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    z-index: 4;

    > .container {
        position: relative;
    }

    .container {
        &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .slideshow {
        height: 500px;
        width: 1260px;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &--video {
            margin: auto;
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            position: absolute;
            gap: 18px;
            right: 15px;
            bottom: 0;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                width: 2px;
                height: 25px;
                transition: .25s;
                background-color: $color-white;
                opacity: 1;
                border-radius: 0;
    
                &:hover, &:focus {
                    background-color: $color-main;
                }
    
                &-active {
                    height: 45px;
                    opacity: 0.5;
                }
            }
        }
    }

    .search-tools {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 80px;
        z-index: 1;
        height: 50px;

        .searchInput {
            border: none;
            background-color: white;
            border-radius: 50px;
            width: 560px;
            height: 50px;
            font-family: $font-family--heading;
            font-weight: $font-weight-normal;
            font-size: 14px;
            line-height: 20px;
            color: $color-dark;

            &::placeholder {
                font-family: $font-family--heading;
                font-weight: $font-weight-normal;
                font-size: 14px;
                line-height: 20px;
                color: $color-dark;
            }
        }

        .search-container {
            position: relative;
            z-index: 16;

            .search-svg {
                transition: all $duration ease-in-out;
    
                &:hover, &:focus {
                    transform: scale(1.2);
                }

                svg {
                    width: 22px;
                    height: 22px;
                    fill: $color-dark;
                    
                }
            }
        }

        .searchResult {
            padding-top: 50px;
            top: -50px;
            border-radius: 30px;
            filter: drop-shadow(0 0 5px rgba(0,0,0,.1));
            background-color: $color-white;

            li {
                font-family: $font-family--heading;
                font-weight: $font-weight-normal;
                font-size: 16px;
                line-height: 20px;
            }

            li:first-child {
                border-top: 1px solid $color-light;
            }

            li:last-child {
                border-radius: 0 0 30px 30px;

                a {
                    border-radius: 0 0 30px 30px;
                }
            }
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    background-color: $color-second;
    // padding: 160px 0 80px;
    padding: 160px 0 5px;
    margin-top: -80px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        background-image: url(/wp-content/themes/st-quay-portrieux/assets/src/images/sprite-images/home/forme-actus.svg);
        background-repeat: no-repeat;
        width: 830px;
        height: 550px;
        top: -65px;
        left: calc(50% + 420px);
        z-index: 3;
    }

    &__container {
        display: flex;
        flex-direction: row-reverse;
    }

    &__header {
        transform: rotate(-90deg);
        height: fit-content;
        margin-top: 215px;
    }

    &__title, &__description {
        transform: translate(15px, -40px);
    }

    &__swiper {
        padding-bottom: 40px;
        border-bottom: none;
        margin: 0;
    }

    &__list {
        width: 970px;
        justify-content: space-between;
    }

    &__item {
        border-top: 1px solid rgba(34, 34, 34, 0.5);
        position: relative;
        width: 470px;

        &:before {
            position: absolute;
            content: "";
            width: 0;
            height: 1px;
            left: 0;
            top: 0;
            background-color: $color-main;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            &:before {
                width: 100%;
            }

            h3 {
                color: $color-main;
                opacity: 0.6;
            }
        }
    }

    &__category {
        font-family: $font-family;
        font-weight: 275;
        font-size: 20px;
        line-height: 25px;
        text-transform: uppercase;
        margin: 10px 0;
    }

    h3 {
        margin-bottom: 20px;
        min-height: 50px;
    }

    &__image {
        position: relative;
        overflow: hidden;
        width: 470px;
        height: 220px;
        
        img {
            transform: scale(1);
            transition: all $duration ease;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light;
            background-position: center;
            width: 470px;
            height: 220px;
        }
    }

    &__introduction {
        margin-top: 15px;
    }

    &__navigation {
        position: absolute;
        bottom: 152px;
        left: calc(50% + 420px);

        &__prev,
        &__next {
            top: unset;
            right: unset;
            left: unset;
            bottom: unset;
            margin-top: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 12px;
            margin: 0;
            padding: 0;
            transition: all $duration ease;

            &::after {
                position: relative;
                content: "";
                top: 0;
                width: 50px;
                height: 12px;
                mask-repeat: no-repeat;
                background-color: $color-dark;
                transition: all $duration ease;
            }
        }

        &__prev {
            &::after {
                mask-image: url(/wp-content/themes/st-quay-portrieux/assets/src/images/sprite-images/Pictos/ic-arrow-left.svg);
                left: -4px;
            }

            &:hover, &:focus {
                transform: translateX(-10px);

                &:after {
                    background-color: $color-main
                }
            }
        }

        &__next {
            margin-top: 25px;
            &::after {
                mask-image: url(/wp-content/themes/st-quay-portrieux/assets/src/images/sprite-images/Pictos/ic-arrow-right.svg);
                left: 0px;
            }

            &:hover, &:focus {
                transform: translateX(10px);

                &:after {
                    background-color: $color-main
                }
            }
        }
    }

    .news-progress {
        width: 970px;
        height: 1px !important;
        position: relative !important;
        display: flex;
        background: rgba(34,34,34,.5)!important;
        margin-top: 25px;
        margin-bottom: 2px;
        transition: $duration;
    
        &__bar {
            width: 0;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            z-index: 1;
            background-color: $color-dark;
            transition: $duration;
        }
    
        .swiper-pagination-progressbar-fill {
            height: 2px !important;
            background: $color-dark !important;
        }
    }
    
    @keyframes progressNews {
        from {
          width: 0;
        }
    
        to {
          width: 970px;
        }
    }

    &__socials {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        padding: 25px 0;
        height: 75px;
        transform: translateY(40.5px);
        position: relative;

        &:before {
            position: absolute;
            top: 0;
            left: calc(50% + 390px);
            width: 575px;
            height: 100%;
            content: "";
            background-color: $color-white;
            z-index: 0;
        }

        a {
            width: 35px;
            height: 35px;
            position: relative;

            svg {
                fill: $color-dark;
                width: 100%;
                height: 100%;
                transition: all 0.2s ease-in-out;
            }

            &:hover, &:focus {
                svg {
                    fill: $color-main;
                    transform: scale(1.2);
                    opacity: 0.8;
                }
            }
        }
    }
}

//======================================================================================================
// Évènements
//======================================================================================================

.home-events {
    margin-top: 80px;
    padding-bottom: 85px;

    &__container {
        display: flex;
        gap: 50px;
    }

    .title {
        padding-left: 1em;
        grid-column: 1 / -1;
        grid-row: 1;
    }

    &__header {
        position: relative;
        display: inline-block;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/st-quay-portrieux/assets/src/images/sprite-images/home/forme-agenda.svg);
            background-repeat: no-repeat;
            width: 1100px;
            height: 550px;
            top: 115px;
            left: calc(10% + -875px);
            z-index: -1;
        }
    }

    &__swiper {
        margin: 6px 0 0;
        padding-bottom: 40px;
        border-bottom: none;
    }

    &__wrapper {
        width: 870px;
        justify-content: space-between;
    }

    &__item {
        border-top: 1px solid rgba(34, 34, 34, 0.5);
        position: relative;
        width: 270px;

        &:before {
            position: absolute;
            content: "";
            width: 0;
            height: 1px;
            left: 0;
            top: 0;
            background-color: $color-main;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            &:before {
                width: 100%;
            }

            h3 {
                color: $color-main;
                opacity: 0.6;
            }
        }
    }
    
    &__date {
        font-family: $font-family;
        font-weight: 275;
        font-size: 20px;
        line-height: 25px;
        text-transform: uppercase;
        margin: 10px 0;
        display: flex;
        gap: 6px;
    }

    h3 {
        margin-bottom: 20px;
        min-height: 50px;
    }

    &__image {
        position: relative;
        width: 270px;
        height: 180px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            transform: scale(1);
            transition: all $duration ease;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-second;
        background-position: center;
        position: relative;
        width: 270px;
        height: 180px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__navigation {
        position: absolute;
        bottom: 72px;
        left: calc(10% - 170px);
        display: flex!important;

        &__prev,
        &__next {
            top: unset;
            right: unset;
            left: unset;
            bottom: unset;
            margin-top: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 12px;
            margin: 0;
            padding: 0;
            transition: all $duration ease;

            &::after {
                position: relative;
                content: "";
                top: 0;
                width: 50px;
                height: 12px;
                mask-repeat: no-repeat;
                background-color: $color-dark;
                transition: all $duration ease;
            }
        }

        &__prev {
            &::after {
                mask-image: url(/wp-content/themes/st-quay-portrieux/assets/src/images/sprite-images/Pictos/ic-arrow-left.svg);
                left: -4px;
            }

            &:hover, &:focus {
                transform: translateX(-10px);

                &:after {
                    background-color: $color-main
                }
            }
        }

        &__next {
            margin-top: 25px;
            &::after {
                mask-image: url(/wp-content/themes/st-quay-portrieux/assets/src/images/sprite-images/Pictos/ic-arrow-right.svg);
                left: 0px;
            }

            &:hover, &:focus {
                transform: translateX(10px);

                &:after {
                    background-color: $color-main
                }
            }
        }
    }

    &__bottom {
        position: relative;
        margin-left: 305px;
    }

    &__button {
        &:before {
            background-color: $color-second;
        }
    }

    .home-events-progress {
        width: 865px;
        height: 1px !important;
        position: relative !important;
        display: flex;
        background: rgba(34,34,34,.5)!important;
        margin-bottom: 2px;
        float: right;
        transition: $duration;
    
        &__bar {
            width: 0;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            z-index: 1;
            background-color: $color-dark;
            transition: $duration;
        }
    
        .swiper-pagination-progressbar-fill {
            height: 2px !important;
            background: $color-dark !important;
        }
    }
    
    @keyframes progressEvents {
        from {
            width: 0;
        }
    
        to {
            width: 865px;
        }
    }
}

//======================================================================================================
// Zoom sur
//======================================================================================================

.zoom {
    background-color: $color-second;
    padding: 80px 0;

    &__container {
        position: relative;
    }

    &__bloc {
        margin-top: 20px;
        
        &:hover, &:focus {
            h3 {
                color: $color-main;
                opacity: 0.6;
            }

            .zoom__button {
                &:after {
                    height: 15px;
                }
            }
        }
    }

    &__item {
        display: flex;
        gap: 30px;
    }

    &__wrapper {
        position: relative;
        z-index: 2;
    }

    &__wrapper {
        position: relative;
        z-index: 1;
    }

    &__image {
        width: 770px;
        height: 400px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        border-top: 1px solid rgba(34, 34, 34, 0.5);
        border-bottom: 1px solid rgba(34, 34, 34, 0.5);
        padding: 15px 0 30px;
        position: relative;
    }

    &__title--small {
        margin-bottom: 20px;
    }

    &__description {
        font-family: $font-family--heading;
        font-weight: $font-weight-normal;
        font-size: 20px;
        line-height: 22px;
        margin-top: 10px;
    }
}

//======================================================================================================
// Partenaires
//======================================================================================================

.partners {
    margin: 100px 0;

    &__blocs {
        display: flex;
        justify-content: space-between;
    }

    &__bloc {
        width: 270px;
        border-top: 1px solid rgba(34, 34, 34, 0.5);
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 0;
            height: 1px;
            left: 0;
            top: 0;
            background-color: $color-main;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            &:before {
                width: 100%;
            }

            .partners__title--small {
                color: $color-main;
                opacity: 0.8;
            }
        }
    }

    &__title--small {
        font-family: $font-family;
        font-weight: 275;
        font-size: 20px;
        line-height: 25px;
        text-transform: uppercase;
        margin: 10px 0 20px;
    }

    &__image {
        width: 270px;
        height: 180px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        &__title {
            font-size: 50px;
            line-height: 50px;
        }

        &__description {
            font-size: 18px;
        }
    }

    .home-slideshow {
        .slideshow {
            width: 1030px;
            height: 410px;
        }
    }

    .news {
        &:before {
            left: calc(50% + 350px);
            top: -35px;
            background-size: 680px;
        }

        &__container {
            flex-direction: unset;
        }

        &__header {
            position: absolute;
            right: 95px;
            margin-top: 193px;
        }

        &__title, &__description {
            transform: translate(15px,-50px);
        }

        &__list {
            width: 780px;
        }

        &__item {
            width: 380px;
        }

        &__image {
            width: 380px;
            height: 180px;
    
            &--notimg {
                width: 380px;
                height: 180px;
            }
        }

        &__list {
            width: 780px;
        }

        &__navigation {
            left: calc(50% + 335px);
        }

        .news-progress {
            width: 780px;
        }
        
        @keyframes progressNews {
            from {
              width: 0;
            }
        
            to {
              width: 780px;
            }
        }

        &__socials {
            &:before {
                left: calc(50% + 280px);
                width: 320px;
            }
        }
    }

    .home-events {
        &__container {
            gap: 35px;
        }

        &__header {
            &:before {
                left: calc(10% - 760px);
                background-size: 950px;
                top: 140px;
            }
        }

        &__swiper {
            width: 700px;
        }

        &__wrapper {
            width: 700px;
        }

        &__item {
            width: 220px;
        }

        &__image {
            width: 220px;
            height: 150px;
        }
    
        .notimg {
            width: 220px;
            height: 150px;
        }

        h3 {
            min-height: 75px;
        }

        &__navigation {
            left: calc(10% - 200px);
        }

        &__bottom {
            margin-left: 245px;
        }

        .home-events-progress {
            width: 700px;
        }
        
        @keyframes progressEvents {
            from {
              width: 0;
            }
        
            to {
              width: 700px;
            }
        }
    }

    .zoom {
        &__description {
            margin-top: 5px;
        }

        &__item {
            gap: 20px;
        }

        &__image {
            width: 620px;
            height: 400px;
        }

        &__description {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .partners {
        &__bloc {
            width: 220px;
        }

        &__image {
            width: 220px;
            height: 150px;
        }

        &__title--small {
            font-size: 17px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .front-page {
        animation: none;
    }
    .home-slideshow {
        .slideshow {
            width: 710px;
            height: 290px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }

        .search-tools {
            display: none;
        }
    }

    .news {
        padding: 145px 0 5px;

        &:before {
            display: none;
        }

        &__container {
            flex-direction: column;
        }

        &__header {
            position: relative;
            transform: none;
            left: 0;
            margin-top: 0;
        }

        &__description, &__title {
            transform: unset;
        }

        &__list {
            width: 620px;
        }

        &__item {
            width: 300px;
        }

        &__image {
            width: 300px;
            height: 145px;
    
            &--notimg {
                width: 300px;
                height: 145px;
            }
        }

        &__swiper {
            width: 620px;
            min-width: 620px;
            margin-top: 30px;
        }

        &__navigation {
            left: calc(50% + 260px);
            top: 175px;
        }

        .news-progress {
            width: 620px;
        }
        
        @keyframes progressNews {
            from {
              width: 0;
            }
        
            to {
              width: 620px;
            }
        }

        &__socials {
            padding: 25px 110px 25px 0;

            &:before {
                left: calc(50% + 5px);
                width: 475px;
            }
        }
    }

    .home-events {
        padding-bottom: 80px;

        &__container {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        &__header {
            &:before {
                display: none;
            }
        }

        &__wrapper {
            width: 620px;
        }

        &__swiper {
            width: 620px;
            min-width: 620px;
            margin: 0;
        }

        &__item {
            width: 300px;
        }

        &__image {
            width: 300px;
            height: 205px;
        }
    
        .notimg {
            width: 300px;
            height: 205px;
        }

        &__bottom {
            margin-left: 0;
        }

        &__navigation {
            left: calc(50% + 260px);
            bottom: 500px;
        }

        .home-events-progress {
            width: 620px;
        }
        
        @keyframes progressEvents {
            from {
              width: 0;
            }
        
            to {
              width: 620px;
            }
        }
    }

    .zoom {
        &__bloc {
            margin-top: 15px;
        }

        &__item {
            flex-direction: column;
        }
    }

    .partners {
        &__blocs {
            flex-wrap: wrap;
            gap: 30px 20px;
        }

        &__bloc {
            width: 300px;
        }

        &__image {
            width: 300px;
            height: 205px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home-slideshow {
        .slideshow {
            width: 640px;
            height: 270px;
        }
    }

    .news {
        padding: 130px 0 50px;

        &__swiper {
            width: 300px;
            min-width: 300px;
            margin-top: 30px;
        }

        &__list {
            gap: 0;
            width: 300px;
        }

        &__navigation {
            left: calc(50% + 100px);
            top: 160px;
        }

        .news-progress {
            width: 300px;
        }
        
        @keyframes progressNews {
            from {
              width: 0;
            }
        
            to {
              width: 300px;
            }
        }

        &__socials {
            justify-content: flex-start;
            padding: 25px 20px;
            transform: translateY(86.5px);

            &:before {
                left: 0;
                width: 475px;
            }
        }
    }
    
    .home-events {
        margin-top: 115px;
        &__swiper {
            width: 300px;
            min-width: 300px;
        }

        &__wrapper {
            width: 300px;
        }

        &__navigation {
            left: calc(50% + 100px);
        }

        .home-events-progress {
            width: 300px;
        }
        
        @keyframes progressEvents {
            from {
              width: 0;
            }
        
            to {
              width: 300px;
            }
        }
    }

    .zoom {
        &__item {
            align-items: center;
        }

        &__image {
            width: 360px;
            height: 230px;
        }
    }

    .partners {
        margin: 100px 0 115px;
    }
}
